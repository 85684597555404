import React from "react";
import { useParams } from "react-router-dom";
import { getLocale } from "../../../system/helpers/localstorageHelper";
import { useNewsContent } from "../apiHooks";
import LandingTemplate from "../../Layouts/LandingTemplate";
import {Spin, Typography} from "antd";
import { PostView } from "../components/PostView";

export function NewsContent() {
  const { slug } = useParams();
  const locale = getLocale();
  const { data, isLoading } = useNewsContent({
    language: locale,
    slug: slug,
  });

  return (
    <LandingTemplate wrapClass={"default__bg-layer"}>
      <section className="news-list">
        <Spin spinning={isLoading}>
          <Typography.Title level={2} className="page-title">{data?.post.html_title}</Typography.Title>
          <br />
          {data && (
            <div key={data.post.id}>
              <PostView post={data.post} />
            </div>
          )}
        </Spin>
      </section>
    </LandingTemplate>
  );
}
