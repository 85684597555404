import React from "react";
import config from "../../../system/config";

const BenefitCardItem = ({ info }) => {
  const { icon, title, text } = info;
  return (
    <div className="benefit-card">
      <div className="benefit-card__icon">
        <img src={`${config.imagesDirectory}/our-benefits/${icon}`} alt="" />
      </div>
      <h3 className="benefit-card__title">{title}</h3>
      <h4 className="benefit-card__text">{text}</h4>
    </div>
  );
};

export default BenefitCardItem;
