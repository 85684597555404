import React from "react";
import classNames from "classnames";
import CustomButton from "../../Common/components/CustomButton";
import { _t } from "../../Common/components/InjectIntlContext";

export type bannerSlideProps = {
  className?: string;
  backgroundImg?: string;
  title?: string;
  subtitle?: string;
  btnLabel?: string;
  linkTo?: string;
  onClick?: () => any;
  idx: number;
};

const BannerSlide: React.FC<bannerSlideProps> = (props) => {
  const { className, title, subtitle, linkTo, onClick, idx } = props;

  return (
    <div className={classNames(className, "banner-slide")}>
      <div className={`banner-slide__bg banner-slide__bg--${idx}`} />
      <div className="container">
        <h2 className="banner-slide__title">{title}</h2>
        <p className="banner-slide__sub-title">{subtitle}</p>
        <CustomButton
          className={"btn btn--second banner-slide__btn"}
          btnLabel={_t("get_started")}
          linkTo={linkTo}
          onClick={onClick}
        />
      </div>

    </div>
  );
};

export default BannerSlide;
