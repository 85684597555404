import React from "react";
import { Form, TreeSelect } from "antd";
import { _t } from "../../../modules/Common/components/InjectIntlContext";
import { useGetDataWithoutParams } from "../../hooks";
import { API_ROUTES } from "../../../system/constants/apiRoutes";

const ProgramTypesSelect = () => {
  const { data, isLoading, isSuccess } = useGetDataWithoutParams(
    API_ROUTES.reports.filters.programs.key,
    API_ROUTES.reports.filters.programs.path
  );
  const programTypesOptions = isSuccess
    ? Object.keys(data.program_types).map((type) => {
        return {
          label: data.program_types[type],
          value: type,
        };
      })
    : undefined;
  return (
    <Form.Item
      label={_t("program_types")}
      name="program_types_filter"
      rules={[
        {
          required: false,
          message: _t("select_program"),
        },
      ]}
    >
      <TreeSelect
        treeData={programTypesOptions}
        placeholder={_t("select_program")}
        treeCheckable={true}
        showCheckedStrategy={"SHOW_CHILD"}
        maxTagCount={1}
        loading={isLoading}
        allowClear
      />
    </Form.Item>
  );
};

export default ProgramTypesSelect;
