import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { updateLocale } from "../../Locales/actions";
import { Dropdown, Menu } from "antd";
import { getLocale } from "../../../system/helpers/localstorageHelper";
import { flags } from "../../Locales/constants";
import arrowDown from "../../../images/icons/dropdown-arrow.svg";

const DropdownLanguagesMenu = () => {
  const { languages } = useSelector((state) => state.locale);
  const dispatch = useDispatch();
  const languageHandler = (lang) => {
    updateLocale(lang.key)(dispatch);
  };
  return (
    <Menu onClick={languageHandler}>
      {languages &&
      Object.values(languages).map((language, index) => {
        return (
          <Menu.Item key={languages[index].code}>
            <img
              src={flags[languages[index].code]}
              alt={"flag"}
            />
            {languages[index].name}
          </Menu.Item>
        );
      })}
    </Menu>
  );
};

const LandingLanguages = () => {
  const locale = getLocale();
  const { languages } = useSelector((state) => state.locale);
  const language = Object.values(languages).filter(
    (item, index) => languages[index].code === locale
  )[0];
  return (
    <Dropdown
      overlay={<DropdownLanguagesMenu />}
      overlayClassName={"languages-overlay"}
      overlayStyle={{ zIndex: 10000 }}
    >
      <span className="language-dropdown">
        <img className="language-dropdown__flag" src={flags[locale]} alt={"flag"} />
        {language && language["name"]}
        <img className="language-dropdown__arrow" src={arrowDown} alt="arrow-down"/>
      </span>
    </Dropdown>
  );
};

export default LandingLanguages;
