import { useMutation, useQuery } from "react-query";
import { getTranslation, GetTranslationRequest, postContacts } from "./api";
import { _t } from "../Common/components/InjectIntlContext";
import { notify, notifyMap } from "../../common/helpers";

const TranslationQuery = "translation";

export const useTranslation = (params: GetTranslationRequest) => {
  return useQuery([TranslationQuery, params], (k, params) =>
    getTranslation(params)
  );
};

export const usePostContacts = () => {
  const messageSentTranslated = _t("message_sent");
  return useMutation(postContacts, {
    onSuccess: (data, variables) => {
      if (data.status === "ok") {
        notify("success", messageSentTranslated);
      } else {
        notifyMap("error", data.message);
      }
    },
    onError: (error) => {
      notify("error", "error");
    },
  });
};
