import React from "react";
import { _t } from "../../Common/components/InjectIntlContext";
import { Typography } from "antd";
import BenefitCardItem from "./BenefitCardItem";

const slides = (_t: any) => [
  {
    title: _t("benefit_title_1"),
    text: _t("benefit_1"),
    icon: "image_1.png",
  },
  {
    title: _t("benefit_title_2"),
    text: _t("benefit_2"),
    icon: "image_2.png",
  },
  {
    title: _t("benefit_title_3"),
    text: _t("benefit_3"),
    icon: "image_3.png",
  },
];

const OurBenefits: React.FC = () => {
  const benefitCards = slides(_t);
  const benefit = (card, idx) => (
    <li className="benefits-grid__item">
      <BenefitCardItem info={card} key={card.title + idx} />
    </li>
  );

  return (
    <section className="our-benefits custom-section">
      <div className="container">
        <Typography.Title level={2} className="custom-section__title">
          {_t("our_benefits")}
        </Typography.Title>
        <ul className="benefits-grid">{benefitCards.map(benefit)}</ul>
      </div>
    </section>
  );
};

export default OurBenefits;
