import React from "react";

import { _t } from "../../Common/components/InjectIntlContext";
import { APP_ROUTES } from "../../../system/constants/constantsUrl";
import NavCard from "./NavCard";

const navCardsConfig = (_t: any) => [
  {
    title: _t("partner_name"),
    text: _t("nav_card_text_1"),
    icon: "image_1.png",
    innerLink: APP_ROUTES.registration,
  },
  {
    title: _t("news"),
    text: _t("nav_card_text_2"),
    icon: "image_2.png",
    innerLink: APP_ROUTES.news.list,
  },
  {
    title: _t("streamspace"),
    text: _t("nav_card_text_3"),
    icon: "image_3.png",
    innerLink: APP_ROUTES.commissions.commission,
  },
  {
    title: _t("contacts"),
    text: _t("nav_card_text_4"),
    icon: "image_4.png",
    innerLink: APP_ROUTES.contacts,
  },
];

export type NavCardProps = {};

const NavCards: React.FC<NavCardProps> = () => {
  const navCards = navCardsConfig(_t).map((item, idx) => (
    <li key={item.title + idx} className="nav-cards__list-item">
      <NavCard info={item} />
    </li>
  ));

  return (
    <div className="nav-cards">
      <div className="container">
        <ul className="nav-cards__list">{navCards}</ul>
      </div>
    </div>
  );
};

export default NavCards;
