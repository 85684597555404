import React from "react";
import Select from "antd/lib/select";

type SelectInputOptions = {
  value: string;
  label: string;
};

type SelectInputProps = {
  options: SelectInputOptions[];
  defaultValue?: string;
  value?: string;
  mode?: "multiple" | "tags";
  placeholder?: string;
  allowClear?: boolean;
  disabled?: boolean;
  loading?: boolean;
  style?: React.CSSProperties;
  suffixIcon?: React.ReactNode;
  onDropdownVisibleChange?: (open: boolean) => void;
};

const SelectInput: React.FC<SelectInputProps> = ({
  mode,
  style,
  placeholder,
  defaultValue,
  options,
  allowClear,
  disabled,
  loading,
  ...rest
}) => {
  return (
    <Select
      mode={mode}
      style={style}
      placeholder={placeholder}
      defaultValue={defaultValue}
      allowClear={allowClear}
      disabled={disabled}
      data-testid={"select-id"}
      options={options}
      loading={loading}
      {...rest}
    ></Select>
  );
};

SelectInput.defaultProps = {
  style: { width: "100%" },
  placeholder: "Please select",
  options: [],
  allowClear: true,
};

export default SelectInput;
