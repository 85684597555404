import React, { useState } from "react";
import { _t } from "../../Common/components/InjectIntlContext";
import { Typography } from "antd";
import config from "../../../system/config";

const slides = (_t: any) => [
  {
    title: _t("how_1"),
    icon: "image_1.png",
  },
  {
    title: _t("how_2"),
    icon: "image_2.png",
  },
  {
    title: _t("how_3"),
    icon: "image_3.png",
  },
  {
    title: _t("how_4"),
    icon: "image_4.png",
  },
];

const HowItWorks: React.FC = () => {
  const [activeItem, setActiveItem] = useState(1);
  const howItWorksCards = slides(_t);
  return (
    <section className="how-it-works custom-section">
      <div className="container">
        <div className="how-it-works-image">
          <img
            src={`${config.imagesDirectory}/how-it-works/image_${activeItem}.png`}
            alt=""
          />
        </div>
        <div className="how-it-works-content">
          <div className="how-it-works-content__wrap">
            <Typography.Title level={2} className="custom-section__title">
              {_t("how_it_works")}
            </Typography.Title>
            <ul className="how-it-works-nav">
              {howItWorksCards.map((item, idx) => (
                <li key={idx}>
                  <button
                    onClick={() => setActiveItem(idx + 1)}
                    className={`${idx + 1 === activeItem ? "active" : ""}`}
                  >
                    <span>{item.title}</span>
                  </button>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </section>
  );
};

export default HowItWorks;
