import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import BannerSlide from "./BannerSlide";
import { _t } from "../../Common/components/InjectIntlContext";
import { onGetStarted } from "../constants";

export type mainBannerSliderProps = {};

const bannerSlides = (_t: any) => [
  {
    title: _t("main_slide_1_title"),
    subtitle: _t("main_slide_1_text"),
    onClick: onGetStarted,
  },
  {
    title: _t("main_slide_2_title"),
    subtitle: _t("main_slide_2_text"),
    onClick: onGetStarted,
  },
  {
    title: _t("main_slide_3_title"),
    subtitle: _t("main_slide_3_text"),
    onClick: onGetStarted,
  },
];

const slickSettings = {
  dots: true,
  infinite: true,
  arrows: false,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 8000,
  fade: true,
};

const MainBannerSlider: React.FC<mainBannerSliderProps> = () => {
  return (
    <div className="main-banner">
      <Slider {...slickSettings}>
        {bannerSlides(_t).map((slide, idx) => (
          <BannerSlide {...slide} idx={idx} key={idx + "bs"} />
        ))}
      </Slider>
    </div>
  );
};

export default MainBannerSlider;
