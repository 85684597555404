import React from "react";
import { Typography } from "antd";
import { _t } from "../../Common/components/InjectIntlContext";

const items = (_t) => [
  {
    title: _t("review_card_1_title"),
    text: _t("review_card_1_text"),
  },
  {
    title: _t("review_card_2_title"),
    text: _t("review_card_2_text"),
  },
  {
    title: _t("review_card_3_title"),
    text: _t("review_card_3_text"),
  },
];
const Reviews = () => {
  const reviewCards = items(_t);
  return (
    <section className="custom-section">
      <div className="container">
        <Typography.Title level={2} className="custom-section__title">
          {_t("reviews_section_title")}
        </Typography.Title>

        <div className="reviews-grid">
          {reviewCards.map((item, idx) => (
            <div key={idx} className="review-card">
              <h3 className="review-card__title">{item.title}</h3>
              <h4 className="review-card__text">{item.text}</h4>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Reviews;
