import React from "react";
import { useSelector } from "react-redux";
import { NotificationContentCard } from "../../components";
import MainLayout from "../../../../../../modules/Layouts/MainLayouts";
import { PageTemplate } from "../../../../../../modules/Layouts/PageTemplate";
import { usePostNotification } from "../../hooks";
import { addTranslationValue } from "../../../../../../common/helpers";
import { Spin } from "antd";

const NotificationCreate = () => {
  const { languages } = useSelector((state) => state.locale);
  const [
    createNotification,
    { isLoading: isPostNotificationLoading },
  ] = usePostNotification();
  const handleSubmit = (values) => {
    createNotification({
      ...values,
      titles: addTranslationValue(languages, values, "titles"),
      texts: addTranslationValue(languages, values, "texts"),
    });
  };
  return (
    <MainLayout>
      <PageTemplate>
        <Spin spinning={isPostNotificationLoading}>
          <NotificationContentCard handleSubmit={handleSubmit} />
        </Spin>
      </PageTemplate>
    </MainLayout>
  );
};

export default NotificationCreate;
