import React from "react";
import { _t } from "../../../Common/components/InjectIntlContext";
import CommissionPage from "./CommissionPage";
import config from "../../../../system/config";

const Revshare = () => {
  const points = (_t: any) => [
    _t("revshare_1"),
    _t("revshare_2"),
    _t("revshare_3"),
    _t("revshare_4"),
  ];
  const title = _t("revshare_title");
  return (
    <CommissionPage
      title={title}
      icon={`${config.imagesDirectory}/commissions/image_1.png`}
      items={points}
    />
  );
};

export default Revshare;
