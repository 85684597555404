import React, { useState } from "react";
import {
  Form,
  Input,
  Button,
  Checkbox,
  Row,
  Col,
  Select,
  Space,
  Card,
} from "antd";
import { UploadOutlined } from "@ant-design/icons";

import { usePostBrandItemUpdate } from "../../hooks";
import { _t } from "../../../../../modules/Common/components/InjectIntlContext";
import { Link } from "react-router-dom";
import { APP_ROUTES } from "../../../../../system/constants/constantsUrl";
import { setFormInputValue } from "../../../../../common/helpers";
import { AffiliateBrandFormProps } from "../../utils";

const AffiliateBrandFormEdit: React.FC<AffiliateBrandFormProps> = ({
  brandInfo,
}) => {
  const [form] = Form.useForm();
  const { Option } = Select;
  const [updateData] = usePostBrandItemUpdate({
    project_id: brandInfo?.id,
  });
  const [logoPreview, setLogoPreview] = useState(brandInfo.image);

  const handleRecommendCheck = (e) => {
    setFormInputValue("is_recommend", e.target.checked ? 1 : 0, form);
  };
  const handleTournamentCheck = (e) => {
    setFormInputValue("tournament", e.target.checked ? 1 : 0, form);
  };
  const uploadImage = (files) => {
    const image = files[0];
    if (!image) return false;
    setFormInputValue("image", image, form);
    setLogoPreview(URL.createObjectURL(image));
  };

  const sendForm = (values) => {
    const formData = new FormData();
    for (const i in values) {
      formData.append(i, values[i]);
    }
    updateData(formData);
  };

  return (
    <Card>
      <Form
        form={form}
        className="brand-edit-form"
        onFinish={sendForm}
        initialValues={{ ...brandInfo }}
        layout="vertical"
      >
        <Row gutter={30}>
          <Col lg={12} span={24}>
            <Form.Item label="id" name="id" hidden>
              <Input />
            </Form.Item>
            <Form.Item
              label={_t("Name")}
              name="name"
              rules={[{ required: true, message: "Please input Brand Name!" }]}
            >
              <Input />
            </Form.Item>
            <Form.Item label="Url" name="url">
              <Input />
            </Form.Item>
            <Form.Item label={_t("status")} name="status">
              <Select>
                <Option value="1">Active</Option>
                <Option value="0">Disabled</Option>
                <Option value="2">Special</Option>
              </Select>
            </Form.Item>
            <Form.Item label={_t("description")} name="description">
              <Input />
            </Form.Item>
            <Form.Item label={_t("sort_order")} name="sort_order">
              <Input />
            </Form.Item>
            <Form.Item name="is_recommend">
              <Checkbox
                value={brandInfo.is_recommend}
                defaultChecked={brandInfo.is_recommend === 1}
                onChange={handleRecommendCheck}
              >
                {_t("is_recommend")}
              </Checkbox>
            </Form.Item>
            <Form.Item name="tournament">
              <Checkbox
                value={brandInfo.tournament}
                defaultChecked={brandInfo.tournament === 1}
                onChange={handleTournamentCheck}
              >
                {_t("tournaments")}
              </Checkbox>
            </Form.Item>
          </Col>
          <Col lg={12} span={24}>
            <div className="brand-edit-form__logo">
              <p>{_t("image")}:</p>
              <img src={logoPreview} alt="" />
            </div>
            <Form.Item name="image">
              <Input.Group>
                <label className="upload-button">
                  <input
                    type="file"
                    accept="image/*"
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      uploadImage(e.target.files)
                    }
                  />
                  <span className="ant-btn ant-btn-round">
                    <UploadOutlined />
                    {_t("upload_logo")}
                  </span>
                </label>
              </Input.Group>
            </Form.Item>
          </Col>
        </Row>
        <Space style={{ marginTop: "40px" }}>
          <Button
            htmlType="submit"
            title={_t("submit")}
            type="primary"
            size="large"
            shape="round"
          >
            {_t("submit")}
          </Button>
          <Link
            to={APP_ROUTES.affiliates_programs.brands}
            className="ant-btn ant-btn-lg ant-btn-round"
          >
            {_t("back")}
          </Link>
        </Space>
      </Form>
    </Card>
  );
};

export default AffiliateBrandFormEdit;
