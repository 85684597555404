import React from "react";
import { APP_ROUTES } from "../../../system/constants/constantsUrl";
import { _t } from "../../Common/components/InjectIntlContext";
import { Typography } from "antd";
import CommissionCard from "./CommissionCard";

const commissions = (_t: any) => [
  {
    title: _t("revshare_commission"),
    image: "image_1.png",
    linkTo: APP_ROUTES.commissions.revshare,
    text: _t("revshare_commission_desc"),
  },
  {
    title: _t("cpa_program"),
    image: "image_2.png",
    linkTo: APP_ROUTES.commissions.cpa,
    text: _t("cpa_program_desc"),
  },
  {
    title: _t("subaffiliate_program"),
    image: "image_3.png",
    linkTo: APP_ROUTES.commissions.subaffiliate,
    text: _t("subaffiliate_program_desc"),
  },
  {
    title: _t("hybrid_program"),
    image: "image_4.png",
    linkTo: APP_ROUTES.commissions.hybrid,
    text: _t("hybrid_program_desc"),
  },
];

const Commissions = () => {
  const commissionsTranslated = commissions(_t);
  const commissionItem = (card, idx) => (
    <CommissionCard info={card} key={card.title + idx} />
  );
  return (
    <section className="commissions custom-section">
      <div className="container">
        <Typography.Title level={2} className="custom-section__title">
          {_t("Commissions")}
        </Typography.Title>
        <div className="commissions-grid">
          {commissionsTranslated.map(commissionItem)}
        </div>
      </div>
    </section>
  );
};

export default Commissions;
