import React from "react";
import { _t } from "../../../Common/components/InjectIntlContext";
import CommissionPage from "./CommissionPage";
import config from "../../../../system/config";

const Hybrid = () => {
  const points = (_t: any) => [
    _t("hybrid_1"),
    _t("hybrid_2"),
    _t("hybrid_3"),
    _t("hybrid_4"),
  ];
  const title = _t("hybrid_program");
  return (
    <CommissionPage
      title={title}
      icon={`${config.imagesDirectory}/commissions/image_4.png`}
      items={points}
    />
  );
};

export default Hybrid;
