import React from "react";
import { _t } from "../../Common/components/InjectIntlContext";
import { Typography } from "antd";
import config from "../../../system/config";

const listItems = (_t: any) => [
  _t("about_item1"),
  _t("about_item2"),
  _t("about_item3"),
  _t("about_item4"),
  _t("about_item5"),
];

const AboutSection: React.FC = () => {
  const listContent = listItems(_t).map((item, idx) => (
    <li key={idx}>{item}</li>
  ));
  return (
    <section className="about-us-section custom-section" id="about-section">
      <div className="container">
        <div className="about-us-section__content">
          <Typography.Title
            level={1}
            className="custom-section__title about-us-section__title"
          >
            {_t("about_us_title")}
          </Typography.Title>
          <ul className="about-list">{listContent}</ul>
          <div className="about-us-section__img">
            <img src={`${config.imagesDirectory}/about-img.png`} alt="" />
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutSection;
