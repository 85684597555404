import React from "react";
import { Form, Input } from "antd";
import { _t } from "../../Common/components/InjectIntlContext";
import config from "../../../system/config";
import { usePostContacts } from "../apiHooks";

const Contacts = () => {
  const [sendContacts, { isLoading }] = usePostContacts();
  const [form] = Form.useForm();

  const submitForm = (values) => {
    console.log(values);
    sendContacts(values);
    form.resetFields();
  };
  return (
    <section className="contact-section">
      <div className="container">
        <Form onFinish={submitForm} form={form} className="contact-form">
          <h2 className="contact-form__title">{_t("contact_section_title")}</h2>
          <p className="contact-form__text">{_t("contact_section_text")}</p>
          <div className="contact-form__wrap">
            <Form.Item
              name="text"
              className="contact-form__item"
              rules={[
                {
                  required: true,
                  message: _t("valid_contacts_msg"),
                },
              ]}
            >
              <Input placeholder={"Skype / Telegram / Mail "} />
            </Form.Item>
            <Form.Item
              name="name"
              className="contact-form__item"
              rules={[
                {
                  required: true,
                  message: _t("input_name"),
                },
              ]}
            >
              <Input placeholder={_t("Name")} />
            </Form.Item>

            <div className="contact-form__item">
              <button
                type="submit"
                className="btn btn--second"
                disabled={isLoading}
              >
                <span>{_t("Send")}</span>
              </button>
            </div>
          </div>
          <div className="contact-form__image">
            <img src={`${config.imagesDirectory}/contact-img.png`} alt="" />
          </div>
        </Form>
      </div>
    </section>
  );
};

export default Contacts;
