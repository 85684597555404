import React from "react";
import { Link } from "react-router-dom";
import { _t } from "../../Common/components/InjectIntlContext";
import config from "../../../system/config";

const NavCard = ({ info }) => {
  const { title, icon, text, innerLink } = info;

  return (
    <div className="nav-card">
      <div className="nav-card__info">
        <p className="nav-card__title">{title}</p>
        <p className="nav-card__text">{text}</p>
        <Link to={innerLink} className="btn btn--second">
          {_t("get_started")}
        </Link>
      </div>
      <div className="nav-card__icon">
        <img src={`${config.imagesDirectory}/nav-cards/${icon}`} alt="" />
      </div>
    </div>
  );
};

export default NavCard;
