import React from "react";
import { FaqResponse } from "../../api";
import { Faqs } from "../Faqs/Faqs";
import { FaqTitle } from "../FaqTitle/FaqTitle";
import { _t } from "../../../Common/components/InjectIntlContext";
import FaqImg from "../../../../images/landings/faq/tile-img.png";
import {FAQ_TITLE_FINANCE, FAQ_TITLE_GENERAL, FAQ_TITLE_TECHNICAL} from "../../constants";
import {Typography} from "antd";

type FaqTitlesProps = {
  data: FaqResponse;
  loading: boolean;
  setFilter: (v) => void;
};

export function FaqTitles({ data, loading, setFilter }: FaqTitlesProps) {
  const tiles = (_t: any) => [
    {
      title: _t("general"),
      tileClass: "general",
      selected: data?.current_category === FAQ_TITLE_GENERAL ? "selected" : "",
      requestParam: FAQ_TITLE_GENERAL,
      image: FaqImg,
    },
    {
      title: _t("financial"),
      tileClass: "financial",
      selected: data?.current_category === FAQ_TITLE_FINANCE ? "selected" : "",
      requestParam: FAQ_TITLE_FINANCE,
      image: FaqImg,
    },
    {
      title: _t("technical"),
      tileClass: "technical",
      selected: data?.current_category === FAQ_TITLE_TECHNICAL ? "selected" : "",
      requestParam: FAQ_TITLE_TECHNICAL,
      image: FaqImg,
    },
  ];
  const tilesTranslated = tiles(_t);

  return (
    <>
      <Typography.Title level={2} className="page-title">{_t("FAQ")}</Typography.Title>
      <section className="faq">
        <div className="faq__tile-cont">
          {tilesTranslated.map((item) => (
            <FaqTitle
              key={item.tileClass}
              data={data}
              requestParam={item.requestParam}
              loading={loading}
              title={item.title}
              setFilter={setFilter}
              tileClass={item.tileClass}
              selected={item.selected}
              image={item.image}
            />
          ))}
        </div>
        <Faqs data={data} loading={loading} />
      </section>
    </>
  );
}
