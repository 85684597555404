import axiosClient from "../../system/helpers/axiosConfig";

export interface TranslationResponse {
  language: string;
  name: string;
  text: string;
}

export interface GetTranslationRequest {
  language: string;
  name: string;
}

export const getTranslation = (params: GetTranslationRequest) => {
  return axiosClient
    .get<TranslationResponse>(`/api/translation`, {
      params,
    })
    .then((r) => r.data);
};
export const postContacts = (params) => {
  return axiosClient
    .post(`/api/feedback/send-contacts`, params)
    .then((r) => r.data.data);
};
